<template lang="">
  <div>
    <div class="row">
      <p class="big_title_delimiter">MENUISERIES</p>
      <div class="column" v-for="(image, i) in imagesMenuiseries" :key="i">
        <img
          class="image"
          :src="image.url"
          :alt="image.alt"
          @click="index = i"
        />
      </div>
      <vue-gallery
        :images="imagesMenuiseries"
        :index="index"
        @close="index = null"
      ></vue-gallery>
    </div>
    <hr />
    <p class="big_title_delimiter">PORTAILS</p>
    <div class="row">
      <div class="column" v-for="(image, j) in imagesPortails" :key="j">
        <img
          class="image"
          :src="image.url"
          :alt="image.alt"
          @click="index = j"
        />
      </div>
      <vue-gallery
        :images="imagesPortails"
        :index="index"
        @close="index = null"
      ></vue-gallery>
    </div>
    <hr />
    <div class="row">
      <p class="big_title_delimiter">METALLERIE</p>
      <div class="column" v-for="(image, k) in imagesMetallerie" :key="k">
        <img
          class="image"
          :src="image.url"
          :alt="image.alt"
          @click="index = k"
        />
      </div>
      <vue-gallery
        :images="imagesMetallerie"
        :index="index"
        @close="index = null"
      ></vue-gallery>
    </div>
  </div>
</template>
<script>
import vueGallery from "/src/components/Gallery.vue";
export default {
  name: "Gallery",
  components: {
    vueGallery,
  },
  metaInfo: {
    title: "Galerie",
    titleTemplate: "%s | LEFLOCH Métal Concept",
  },

  mounted() {
    this.importImages(
      require.context(
        "../../public/gallery/menuiseries",
        true,
        /(\.png|\.jpg)$/
      ),
      this.imagesMenuiseries,
      "menuiseries"
    );
    this.importImages(
      require.context("../../public/gallery/portails", true, /(\.png|\.jpg)$/),
      this.imagesPortails,
      "portails"
    );
    this.importImages(
      require.context(
        "../../public/gallery/metallerie",
        true,
        /(\.png|\.jpg)$/
      ),
      this.imagesMetallerie,
      "metallerie"
    );
  },

  methods: {
    clean(str) {
      return str.substring(2, str.length - 4);
    },
    importImages(r, albumToPush, path) {
      r.keys().forEach((key) =>
        albumToPush.push({
          url: "/gallery/" + path + "/" + key,
          alt: this.clean(key),
          desc: this.clean(key),
        })
      );
    },
  },
  data() {
    return {
      imagesPortails: [],
      imagesMetallerie: [],
      imagesMenuiseries: [],
      index: null,
    };
  },
};
</script>
<style lang="css">
.row {
  display: flex;
  display: -webkit-flex; /* NEW */
  -webkit-flex-wrap: wrap; /* NEW */
  flex-wrap: wrap;
  padding: 0 4px 20px 4px;
  align-content: center;
  justify-content: center;
}
/* Create four equal columns that sits next to each other */
.column {
  -webkit-flex: 25%;
  flex: 25%;
  max-width: 25%;
  min-width: 250px;
  padding: 0 4px;
}

.big_title_delimiter {
  font-size: 2.5em;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

.column .image {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.719);
}

@media screen and (max-width: 800px) {
  .column {
    -webkit-flex: 50%;
    flex: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    -webkit-flex: 100%;
    flex: 100%;
  }
}
</style>
